


















































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import ContactFormContactInfo from "@/components/contact-form/ContactFormContactInfo.vue";
import ContactFormAddressInfo from "@/components/contact-form/ContactFormAddressInfo.vue";
import ContactFormBusinessInfo from "@/components/contact-form/ContactFormBusinessInfo.vue";
import DialogMixin from "@/mixins/DialogMixin";
import Contact, {
  contactToCreateContact,
  CreateContact,
  initialContactData,
} from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";
import BaseForm from "@/components/form/BaseForm";
import CompanyService from "@/services/CompanyService";
import { ProjectType } from "@/entity/Project";
import Company from "@/entity/Company";

@Component({
  components: {
    FormCard,
    ContactFormContactInfo,
    ContactFormAddressInfo,
    ContactFormBusinessInfo,
  },
})
export default class ContactFormCard extends Mixins(DialogMixin, BaseForm) {
  @PropSync("entityId", { type: String }) contactId!: string;
  @Prop() owners!: Contact[];
  data: CreateContact = initialContactData();
  companyId: number | null = 0;
  companies: Company[] = [];

  async submitForm(): Promise<void> {
    try {
      if (this.isEditing) {
        const response = await ContactsService.updateContact(
          this.entityId as number,
          this.data,
          this.companyId
        );
        this.$emit("success", response);
      } else {
        const response = await ContactsService.createContact(
          this.data,
          this.companyId
        );
        this.$emit("success", response);
      }
    } catch (e) {
      if (e.response.status === 409) {
        const confirm = await this.$confirm(
          this.$t("confirmations.contactAlreadyExists").toString()
        );

        if (confirm) {
          await ContactsService.setIsDisabled(e.response.data.message, false);
        }
        await this.$router.push({
          name: "contactDetail",
          params: {
            contactId: e.response.data.message,
          },
        });
      } else if (
        e.response.status === 400 &&
        e.response.data.message === "CONTACT_ALREADY_EXISTS"
      ) {
        this.$snackbarError(this.$tc("apiErrors.contactAlreadyExists"));
        this.$emit("fail");
      } else if (
        e.response.status === 400 &&
        e.response.data.message === "EMAIL_ALREADY_EXISTS"
      ) {
        this.$snackbarError(this.$tc("apiErrors.contactEmailAlreadyExists"));
        this.$emit("fail");
      } else {
        this.$snackbarError(this.$tc("apiErrors.unableToSave"));
        this.$emit("fail");
      }
    }
  }

  async loadForm(): Promise<void> {
    if (this.isEditing) {
      const response = await ContactsService.findOne(this.entityId as number);
      if (response?.company) {
        this.companyId = response?.company.id;
      }
      if (response?.companies && response.companies.length > 0) {
        const companies = this.companies;
        companies.unshift(response?.companies[0]);
        this.companies = companies;
      }
      this.data = contactToCreateContact({
        ...response,
        address: response.address
          ? response.address
          : { address: "", city: "", country: 0, postcode: "" },
      });
    }
  }

  // This is used in ContactFormWrapper
  setData(contact: Partial<CreateContact>): void {
    this.data = {
      ...initialContactData(),
      ...contact,
    };
  }

  openSearchApiDialog(): void {
    this.$emit("openSearchApiDialog");
  }

  async fetchUnusedCompanies(): Promise<void> {
    const companies = await CompanyService.findUnusedCompanies(
      ProjectType.transaction
    );
    companies.unshift({ name: "none", id: 0 } as Company);
    this.companies = companies;
  }

  async created() {
    await this.fetchUnusedCompanies();
    this.handleFormLoad(this.loadForm);
  }
}
